import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  id?: string;
}

const SectionWrap = styled.section`
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f5f8fc),
    color-stop(82.66%, rgba(245, 248, 252, 0.99)),
    to(rgba(245, 248, 252, 0))
  );
  background: -webkit-linear-gradient(
    top,
    #f5f8fc,
    rgba(245, 248, 252, 0.99) 82.66%,
    rgba(245, 248, 252, 0)
  );
  background: -o-linear-gradient(
    top,
    #f5f8fc 0,
    rgba(245, 248, 252, 0.99) 82.66%,
    rgba(245, 248, 252, 0) 100%
  );
  background: linear-gradient(
    180deg,
    #f5f8fc,
    rgba(245, 248, 252, 0.99) 82.66%,
    rgba(245, 248, 252, 0)
  );
  padding-top: 77px;
  position: relative;
`;

const SectionContainer = styled.div`
  background-image: url(/how-it-works/dottedline.png);
  background-repeat: no-repeat;
  padding-bottom: 90px;
  padding-top: 30px;
  width: 100%;
`;

const StyledH3 = styled.h3`
  font-family: ProximaNova-Light;
  font-size: 30px;
  font-weight: 300;
  line-height: 37px;
`;

const StyledH5 = styled.h5`
  border-bottom: 1px solid #dadada;
  color: #000;
  font-family: ProximaNova-Semibold;
  letter-spacing: 1px;
  letter-spacing: 0 !important;
  padding-bottom: 4px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const StyledCardPara = styled.p`
  font-family: ProximaNova-Regular;
`;

const StyledCard = styled(Card)`
  border: none;

  @media (max-width: 767px) {
    background: none;
    text-align: center;
  }
`;

const StyledCardWithImage = styled(StyledCard)`
  &::before {
    background-image: url(/img-1.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 100%;
    bottom: -44px;
    content: "";
    height: 75px;
    left: 0;
    margin: auto;
    position: absolute;
    right: -100px;
    width: 75px;
    z-index: 10;

    @media (max-width: 500px) {
      display: none;
    }
  }
`;

const GiveTogetherSection: React.FC<Props> = ({ id }) => {
  return (
    <SectionWrap
      className="mt-5"
      data-sal="fade"
      data-sal-duration="1000"
      data-sal-delay="100"
      id={id}
    >
      <SectionContainer>
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={8} md={10}>
              <Row>
                <Col md={12} className="text-center mb-5">
                  <StyledH3 className="mb-0">Give together</StyledH3>
                  <span
                    style={{
                      padding: "0 10px",
                      fontSize: "5px",
                      backgroundColor: "#00d0ff",
                    }}
                  ></span>
                  <span
                    style={{
                      padding: "0 30px",
                      fontSize: "5px",
                      marginLeft: "5px",
                      backgroundColor: "blue",
                    }}
                  ></span>
                </Col>

                <Col md={6} className="order-2 order-md-1">
                  <StyledCard style={{ marginTop: "38px" }}>
                    <Card.Body style={{ padding: "36px" }}>
                      <StyledH5>Join or start a Giving Group </StyledH5>
                      <StyledCardPara>
                        With a Giving Group, multiple people can combine forces,
                        pool or raise money, and support one or more charities
                        together.
                      </StyledCardPara>
                    </Card.Body>
                  </StyledCard>
                </Col>

                <Col md={6} className="order-1 order-md-2">
                  <img
                    src="/how-it-works/givinggroup@2x.png"
                    className="img-fluid join-img-1"
                    width="100%"
                    alt=""
                    loading="lazy"
                  />
                </Col>

                <Col md={6} className="mt-5 order-3">
                  <img
                    src="/how-it-works/sendcharitabledollars.png "
                    className="img-fluid join-img-1"
                    width="100%"
                    alt=""
                    loading="lazy"
                  />
                </Col>

                <Col md={6} mt={5} className="order-4">
                  <StyledCardWithImage>
                    <Card.Body style={{ padding: "36px" }}>
                      <StyledH5>
                        Send friends charitable dollars that they can give away
                      </StyledH5>
                      <StyledCardPara>
                        From birthday presents, to children's allowances, to
                        "thank you" gifts, sending charitable dollars to friends
                        and family is a great way to inspire those around you to
                        give.
                      </StyledCardPara>
                    </Card.Body>
                  </StyledCardWithImage>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </SectionContainer>
    </SectionWrap>
  );
};

export default GiveTogetherSection;
