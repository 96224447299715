import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "gatsby";

const SectionWrap = styled.section`
  position: relative;
  padding: 80px 0 180px;
  background-color: #f5f8fc;
`;

const StyledH2 = styled.h2`
  font-family: ProximaNova-Semibold !important;
  color: #282828;
  font-size: 24px;
  line-height: 29px;

  @media only screen and (max-width: 767px) {
    text-align: center;
    margin-top: 30px;
  }
`;

const StyledPara = styled.p`
  text-align: left;
  font-family: ProximaNova-Regular;
  font-size: 19px;

  @media only screen and (max-width: 767px) {
    text-align: center;
    font-size: 17px !important;
  }
`;

const ConnectWithSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col
            md={6}
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-delay="400"
          >
            <img
              src="/how-it-works/donor@2x.png"
              className="img-fluid"
              alt=""
              loading="lazy"
            />
          </Col>
          <Col
            md={6}
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-delay="400"
          >
            <div>
              <StyledH2>The donor-advised fund for everyone</StyledH2>
              <StyledPara>
                Charitable Impact operates as a donor-advised fund. In short,
                this means you can manage all of your charitable giving from a
                single account, which we call the Impact Account.
              </StyledPara>
              <StyledPara>
                When you add money to your Impact Account, you're actually
                making a donation to{" "}
                <Link to="/foundation">
                  CHIMP: Charitable Impact Foundation (Canada)
                </Link>
                , a registered charity and public foundation. It's why you get a
                tax receipt after adding money. The funds you add stay in your
                account until you decide you'd like to send gifts to charities,
                Giving Groups, and other people on Charitable Impact.
              </StyledPara>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default ConnectWithSection;
