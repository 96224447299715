import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  padding: 80px 0;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const StyledH3 = styled.h3`
  font-family: ProximaNova-Light;
  font-size: 30px;
  font-weight: 300;
  line-height: 37px;
  margin-bottom: 0;
`;

const StyledCol = styled(Col)`
  background-color: #fff;
  margin-bottom: 40px;
  min-height: 100px;
  position: relative;
`;

const StyledCol3 = styled(StyledCol)`
  &::before,
  &::after {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    position: absolute;
    width: 220px;
  }

  &::before {
    background-image: url(/how-it-works/dot-how-it.png);
    height: 40px;
    right: 38px;
    top: -52px;
  }

  &::after {
    background-image: url(/how-it-works/how_it_dot-3.png);
    bottom: -30px;
    height: 125px;
    left: -55px;
    z-index: -9;
  }
`;

const StyledCol4 = styled(StyledCol)`
  &::before {
    background-image: url(/how-it-works/dot-how-it-4.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -53px;
    content: "";
    height: 160px;
    position: absolute;
    right: -51px;
    width: 130px;
    z-index: -9;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const StyledH5 = styled.h5`
  color: #282828;
  font-family: ProximaNova-Semibold;
  font-size: 20px;
  line-height: 24px;
`;

const StyledPara = styled.p`
  font-family: ProximaNova-Regular;
`;

const ImpactDetailsSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col
            md={{ span: 10, offset: 1 }}
            sm={12}
            data-sal="fade"
            data-sal-delay="500"
            data-sal-duration="1000"
          >
            <Row>
              <Col md={12} className="text-center">
                <StyledH3>Impact Account highlights</StyledH3>
                <span
                  style={{
                    padding: "0 30px",
                    fontSize: "5px",
                    backgroundColor: "blue",
                  }}
                ></span>
                <span
                  style={{
                    padding: "0 10px",
                    fontSize: "5px",
                    backgroundColor: "#00d0ff",
                    marginLeft: "5px",
                  }}
                ></span>
              </Col>

              <StyledCol md={6} className="mt-5">
                <StyledH5>Rediscover how good it feels to give</StyledH5>
                <StyledPara>
                  An Impact Account offers you the time and space to think about
                  the change you want to make in the world, give joyfully, and
                  say 'no' to fundraising requests peacefully.
                </StyledPara>
              </StyledCol>

              <StyledCol md={6} className="mt-md-5">
                <StyledH5>Show or hide your contact information</StyledH5>
                <StyledPara>
                  You can give to charities and Giving Groups with full
                  recognition or without sharing your name and contact details.
                </StyledPara>
              </StyledCol>

              <StyledCol3 md={6}>
                <StyledH5>
                  Discover new charities and find your favourites
                </StyledH5>
                <StyledPara>
                  See a list of charities and Giving Groups that you might be
                  interested in and learn more about what they do, or easily
                  search for familiar favourites.
                </StyledPara>
              </StyledCol3>

              <StyledCol4 md={6}>
                <StyledH5>Give automatically</StyledH5>
                <StyledPara>
                  If you'd like, easily schedule monthly deposits into your
                  account and set up monthly charitable gifts to others.
                </StyledPara>
              </StyledCol4>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default ImpactDetailsSection;
