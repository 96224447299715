import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import ButtonWithLink from "../Buttons/ButtonWithLink";

const Sectionwrap = styled.section``;

const StyledContainer = styled(Container)`
  @media (max-width: 1199px) {
    max-width: 100% !important;
    padding: 0 !important;
    width: 100% !important;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const CardContainer = styled.div`
  background-color: #242734;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.11);
  margin-top: -80px;
  padding: 72px 32px 72px 120px;
  position: relative;
  width: 100%;

  @media (max-width: 1199px) {
    border-radius: 0;
  }

  @media (max-width: 466px) {
    height: 500px;
    padding: 118px 42px;
  }
`;

const CardHeading = styled.h1`
  color: #fff;
  font-family: ProximaNova-Light;
  font-size: 30px;
  line-height: 37px;
`;

const CardContent = styled.p`
  color: #fff;
  font-family: ProximaNova-Regular;
  font-size: 18px;
  width: 40%;

  @media (max-width: 1199px) {
    width: 60%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const GiftsIcon = styled.span`
  position: absolute;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
`;

const GiftsIcon1 = styled(GiftsIcon)`
  width: 200px;
  height: 123px;
  bottom: 35px;
  right: 0;
  background-image: url(/how-it-works/how-it-box-icon-1.png);

  @media (max-width: 1199px) {
    bottom: 0;
  }
`;

const GiftsIcon2 = styled(GiftsIcon)`
  width: 320px;
  height: 155px;
  bottom: -47px;
  right: -69px;
  background-image: url(/how-it-works/how-it-box-icon-2.png);

  @media (max-width: 1199px) {
    right: 0;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const GiftsIcon3 = styled(GiftsIcon)`
  width: 240px;
  height: 101px;
  top: 0;
  left: 50%;
  background-image: url(/how-it-works/how-it-box-icon-3.png);

  @media (max-width: 768px) {
    left: 45%;
  }

  @media (max-width: 767px) {
    left: -88px;
    width: 205px;
  }
`;

const GiftsIcon4 = styled(GiftsIcon)`
  width: 126px;
  height: 70px;
  top: -2px;
  right: 0;
  background-image: url(/how-it-works/how-it-box-icon-4.png);
`;

const AdministrationSection = () => {
  return (
    <Sectionwrap data-sal="fade" data-sal-duration="1000" data-sal-delay="500">
      <StyledContainer>
        <CardContainer>
          <CardHeading>Get more of your money to charity</CardHeading>
          <CardContent>
            It's free to open and use an Impact Account. And, more money can get
            to charity when you give with Charitable Impact.
          </CardContent>
          <ButtonWithLink text="Learn more" Linkto="/fees" />
          <GiftsIcon1 />
          <GiftsIcon2 />
          <GiftsIcon3 />
          <GiftsIcon4 />
        </CardContainer>
      </StyledContainer>
    </Sectionwrap>
  );
};

export default AdministrationSection;
