import React from "react";
import SubNav from "../components/Header/SubNav";
import SectionTabs from "../components/Home/sectionTabs";
import AdministrationSection from "../components/HowItWorks/AdministrationSection";
import BannerSection from "../components/HowItWorks/BannerSection";
import ConnectWithSection from "../components/HowItWorks/ConnectWithSection";
import GetStartedSection from "../components/HowItWorks/GetStartedSection";
import GiveTogetherSection from "../components/HowItWorks/GiveTogetherSection";
import HereToHelpSection from "../components/HowItWorks/HereToHelpSection";
import ImpactDetailsSection from "../components/HowItWorks/ImpactDetailsSection";
import InspirationSection from "../components/HowItWorks/InspirationSection";
import OpenAccountSection from "../components/HowItWorks/OpenAccountSection";
import SafeSecureSection from "../components/HowItWorks/SafeSecureSection";
import StrategiesTabSection from "../components/HowItWorks/StrategiesTabSection";
import Layout from "../components/Layout/Layout";

const pageInfo = {
  path: "/how-it-works",
  title: "Details about our donor-advised fund",
  description:
    "Rediscover how good it feels to give with an Impact Account. Learn more about how to support Canadian charities that match the causes that interest you.",
};

const HowTtWorks = () => {
  return (
    <Layout pageInfo={pageInfo}>
      {typeof window !== "undefined" && window.innerWidth > 767 ? (
        <SubNav />
      ) : (
        ""
      )}

      <BannerSection />
      <SectionTabs
        className="mt-0"
        id="howItWorks"
        bannerHeading="Like a bank account for your charitable giving"
        bannerTextLead="With an Impact Account, you can :"
        tabHeading1="Find charities"
        tabHeading2="Add money"
        tabHeading3="Give"
        tabTextContent1="Find charities that match your interests and learn more about what they do."
        tabTextContent2="Add money to your account, then take as much time and space as you need to decide which charities to support."
        tabTextContent3="Give to your favourite charities from your account now, or save some of your charitable dollars and build your impact over time. The choice is yours."
        tabImagePath1="/how-it-works/find-charities.png"
        tabImagePath2="/how-it-works/addmoney.png"
        tabImagePath3="/how-it-works/give.png"
        colClassName="col-xl-10 col-lg-12 col-md-12 col-sm-12"
        headingStyle={
          typeof window !== "undefined" && window.innerWidth > 767
            ? { fontSize: "28px" }
            : { fontSize: "25px" }
        }
        textLeadStyle={
          typeof window !== "undefined" && window.innerWidth < 767
            ? {
                fontSize: "15px",
                textAlign: "center",
                fontFamily: "ProximaNova-Regular",
              }
            : {
                fontSize: "",
                textAlign: "",
                fontFamily: "",
              }
        }
      />
      <ConnectWithSection />
      <GetStartedSection />
      <ImpactDetailsSection />
      <GiveTogetherSection id="giveTogether" />
      <HereToHelpSection id="support" />
      <AdministrationSection />
      <SafeSecureSection />
      <StrategiesTabSection id="strategic" />
      <InspirationSection id="youth" />
      <OpenAccountSection />
    </Layout>
  );
};

export default HowTtWorks;
