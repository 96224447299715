import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import ButtonWithAnchor from "../Buttons/ButtonWithAnchor";

const SectionWrap = styled.section`
  .container {
    @media (max-width: 1199px) {
      max-width: 100%;
      padding: 0;
      width: 100%;
    }
  }
`;

const CardContainer = styled.div`
  background-color: #009ea1;
  border-radius: 4px;
  margin-bottom: 100px;
  margin-top: 80px;
  overflow: hidden;
  padding: 68px 28px;
  position: relative;

  &::before {
    background-image: url(/how-it-works/footer-right.png);
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 341px;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const CardContent = styled.div`
  margin-left: 100px;
  width: 60%;

  @media only screen and (max-width: 767px) {
    margin: auto;
    width: 80%;
    text-align: center;
    padding-bottom: 20px;
  }
`;

const StyledH2 = styled.h2`
  color: #fff;
  font-family: ProximaNova-Light;
  font-size: 30px;
  line-height: 37px;

  @media (max-width: 992px) {
    font-size: 26px;
  }
`;

const StyledPara = styled.p`
  color: #fff;
  font-family: ProximaNova-Regular;
  font-size: 18px;
  line-height: 28px;
`;

const OpenAccountSection = () => {
  return (
    <SectionWrap>
      <Container>
        <CardContainer>
          <Row className="align-items-center">
            <Col
              xl={8}
              lg={8}
              md={12}
              data-sals="fade"
              data-sal-duration="1000"
              data-sal-delay="500"
            >
              <CardContent>
                <StyledH2>
                  Create the change you want to see in the world.
                </StyledH2>
                <StyledPara>
                  Opening a free Impact Account takes about 5 minutes.
                </StyledPara>
              </CardContent>
            </Col>

            <Col
              md={12}
              lg={4}
              xl={4}
              className="text-center text-md-start"
              data-sal-duration="1000"
              data-sal-delay="500"
            >
              <ButtonWithAnchor
                text="Open an Impact Account"
                href="https://my.charitableimpact.com/users/new"
              />
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </SectionWrap>
  );
};

export default OpenAccountSection;
