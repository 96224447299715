import React from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  id?: string;
}

const SectionWrap = styled.section`
  padding-bottom: 100px;
  background-color: #f5f8fc;
`;

const Connect = styled.div`
  padding: 80px 0;

  @media (max-width: 768px) {
    padding: 69px 40px;
  }

  @media (max-width: 767px) {
    padding: 70px 0;
    text-align: center;
  }
`;

const StyledH3 = styled.h3`
  color: #282828;
  font-family: ProximaNova-Light;
  font-size: 30px;
  font-weight: 300;
  line-height: 37px;
`;

const StyledPara = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  font-family: ProximaNova-Regular;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const StyledImageLeft = styled.img`
  margin-left: 170px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const PersonImageWrap = styled.div`
  display: inline-block;
  position: relative;
  text-align: center;
`;

const PersonImageWrap1 = styled(PersonImageWrap)`
  &::after {
    background-image: url(/how-it-works/frame.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 238px;
    left: 0;
    position: absolute;
    top: -30px;
    width: 280px;
    z-index: 1;

    @media only screen and (max-width: 992px) {
      height: 130px;
      width: 220px;
      top: -13px;
    }
  }

  @media (max-width: 1199px) {
    width: 32%;
  }

  @media (max-width: 767px) {
    margin-bottom: 50px;
    margin-top: 50px;
    width: 107px;
  }

  @media (max-width: 480px) {
    margin-bottom: 50px !important;
    margin-top: 50px !important;
    width: 90px !important;
  }
`;

const PersonImageWrap2 = styled(PersonImageWrap)`
  &::before {
    background-image: url(/how-it-works/how-it-dots.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 120px;
    position: absolute;
    right: -10px;
    top: -50px;
    width: 120px;
  }

  @media (max-width: 1199px) {
    width: 32%;
  }

  @media (max-width: 767px) {
    margin-bottom: 50px;
    margin-top: 50px;
    width: 107px;
  }

  @media (max-width: 480px) {
    width: 90px !important;
  }

  img {
    @media (max-width: 767px) {
      -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5);
      z-index: 99;
    }
  }
`;

const PersonImageWrap3 = styled(PersonImageWrap)`
  @media (max-width: 1199px) {
    width: 32%;
  }

  @media (max-width: 767px) {
    margin-bottom: 50px;
    margin-top: 50px;
    width: 107px;
  }

  @media (max-width: 480px) {
    width: 90px !important;
  }
`;

const PersonImage = styled.img`
  margin: auto;
  position: relative;
  text-align: center;
  width: 90%;
  z-index: 10;

  @media (max-width: 767px) {
    z-index: 9;
  }
`;

const StyledPersonCol = styled(Col)`
  margin-top: 120px;

  @media (max-width: 768px) {
    margin-top: 170px !important;
  }

  @media (max-width: 767px) {
    margin-top: 10px !important;
  }
`;

const StyledAnchorLink = styled.a`
  .badge {
    border-radius: 29px;
    font-size: 16px;
    margin-top: 25px;
    padding: 15px;
    width: 100%;
  }
`;

const HereToHelpSection: React.FC<Props> = ({ id }) => {
  return (
    <SectionWrap
      id={id}
      data-sal="fade"
      data-sal-duration="1000"
      data-sal-delay="500"
    >
      <Container>
        <Connect className="justify-content-center">
          <Row>
            <Col
              md={{ span: 5, offset: 1 }}
              lg={{ offset: 1 }}
              className="new_help_offest"
            >
              <StyledImageLeft
                src="/how-it-works/illustration-1.png"
                alt="Image"
              />
              <StyledH3>Here to help</StyledH3>

              <StyledPara>
                Phone, email, or chat with our team any time you need help, want
                more information, or have a question about making the most of
                your giving.
              </StyledPara>
            </Col>

            <StyledPersonCol md={6}>
              <PersonImageWrap1>
                <PersonImage src="/how-it-works/person1.jpg" alt="Person1" />
              </PersonImageWrap1>
              <PersonImageWrap2>
                <PersonImage src="/how-it-works/person2.jpg" alt="Person2" />
              </PersonImageWrap2>
              <PersonImageWrap3>
                <PersonImage src="/how-it-works/person3.jpg" alt="Person3" />
              </PersonImageWrap3>
              <StyledAnchorLink
                href="/how-it-works-form"
                target="_blank"
                className="d-md-none"
              >
                <Badge>Talk to one of our giving experts</Badge>
              </StyledAnchorLink>
            </StyledPersonCol>
          </Row>
        </Connect>
      </Container>
    </SectionWrap>
  );
};

export default HereToHelpSection;
