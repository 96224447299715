import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import ButtonWithAnchor from "../Buttons/ButtonWithAnchor";

const SectionWrap = styled.section`
  margin-top: -160px;

  .container {
    @media only screen and (max-width: 767px) {
      padding: 0;
      text-align: center;
      overflow: hidden;
    }
  }
`;

const CardContainer = styled.div`
  padding: 80px 0 80px;
`;

const CardContent = styled.div`
  padding: 50px 70px;
  background-color: #242734;
  border-radius: 4px;
  position: relative;
  color: #fff;
  background-image: none;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;

  @media only screen and (max-width: 767px) {
    border-radius: 0;
  }

  &::before {
    position: absolute;
    content: "";
    left: -115px;
    background-image: url(/bg/dotbg.svg);
    height: 229px;
    width: 200px;
    top: -34px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }

  &::after {
    position: absolute;
    content: "";
    right: 0;
    background-image: url(/get_started.png);
    height: 212px;
    width: 315px;
    top: -24px;
    left: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media only screen and (max-width: 767px) {
      display: block !important;
      top: -62px !important;
      height: 262px !important;
      width: 179px !important;
    }
  }
`;

const TopContent = styled.div`
  position: relative;
  z-index: 999;

  @media only screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
`;

const StyledH3 = styled.h3`
  color: inherit;
  font-family: ProximaNova-Light;
  font-weight: 300;
`;

const StyledPara = styled.p`
  font-size: 18px;
  margin-bottom: 0;
  color: inherit;
  font-family: ProximaNova-Light;
  font-weight: 300;
`;

const GetStartedSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={12}>
            <CardContainer>
              <CardContent>
                <Row className="align-items-center">
                  <Col
                    md={6}
                    data-sal="fade"
                    data-sal-duration="1000"
                    data-sal-delay="500"
                  >
                    <TopContent>
                      <StyledH3>Get started</StyledH3>
                      <StyledPara>
                        Opening a free Impact Account takes about 5 minutes.
                      </StyledPara>
                    </TopContent>
                  </Col>

                  <Col md={6} className="text-center text-md-end">
                    <ButtonWithAnchor
                      text="Open an Impact Account"
                      href="https://my.charitableimpact.com/users/new"
                    />
                  </Col>
                </Row>
              </CardContent>
            </CardContainer>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default GetStartedSection;
