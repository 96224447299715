import React, { useState, useEffect } from "react";
import Scrollspy from "react-scrollspy";
import { Container, Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";

const SubNavWrap = styled.div`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  transform: translateY(-80px);
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  z-index: 99;

  &.false {
    -webkit-transform: translateY(77px) !important;
    -ms-transform: translateY(77px) !important;
    transform: translateY(77px) !important;
  }
`;

const StyledNavbar = styled(Navbar)`
  background-color: #fafafd;
  border: 1px solid #e5e5e7;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 4px 11px 0 hsl(0deg 0% 53% / 18%);
  box-shadow: 0 4px 11px 0 hsl(0deg 0% 53% / 18%);
  padding: 20px 1rem;
`;

const StyledNavItem = styled(Nav.Item)`
  &.active {
    a {
      color: #9142a1 !important;
      font-family: ProximaNova-Regular;
      font-weight: 600 !important;
    }
  }
`;

const StyledNavLink = styled(Nav.Link)`
  color: #616161 !important;
  display: inline-block;
  font-family: ProximaNova-Regular;
  margin: 0 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
`;

const SubNav = () => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <SubNavWrap id="top-menu" className={`active ${show && "hidden"}`}>
      <Container>
        <StyledNavbar expand="lg">
          <Container>
            <Navbar.Collapse>
              <Nav
                as={Scrollspy}
                className="mx-auto"
                items={[
                  "howItWorks",
                  "giveTogether",
                  "support",
                  "strategic",
                  "youth",
                ]}
                currentClassName="active"
                offset={-10}
              >
                <StyledNavItem as="li">
                  <StyledNavLink href="#howItWorks">How it works</StyledNavLink>
                </StyledNavItem>
                <StyledNavItem as="li">
                  <StyledNavLink href="#giveTogether">
                    Give together
                  </StyledNavLink>
                </StyledNavItem>
                <StyledNavItem as="li">
                  <StyledNavLink href="#support">
                    Support and security
                  </StyledNavLink>
                </StyledNavItem>
                <StyledNavItem as="li">
                  <StyledNavLink href="#strategic">
                    Strategic advice
                  </StyledNavLink>
                </StyledNavItem>
                <StyledNavItem as="li">
                  <StyledNavLink href="#youth">Youth programs</StyledNavLink>
                </StyledNavItem>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </StyledNavbar>
      </Container>
    </SubNavWrap>
  );
};

export default SubNav;
