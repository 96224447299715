import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  id?: string;
}

const SectionWrap = styled.section`
  padding-top: 140px;
`;

const StyledH3 = styled.h3`
  color: #282828;
  font-family: ProximaNova-Light;
  font-size: 30px;
  font-weight: 300;
  line-height: 37px;
`;

const StyledPara = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
`;

const CardBox = styled.div`
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 17px 0 hsl(0deg 0% 53% / 50%);
  box-shadow: 0 4px 17px 0 hsl(0deg 0% 53% / 50%);
  height: 100%;
  min-height: 400px;
  position: relative;
  width: 100%;
  text-align: center;

  @media (max-width: 1199px) and (min-width: 768px) {
    min-height: 420px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -99;
    width: 124px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &:hover {
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 100%;
    min-height: 400px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;

    h2 {
      color: #fff;
    }

    p {
      display: none;
    }

    .btn {
      background-color: #fff;
      border: 1px solid #007bff;
      color: #007bff;
    }
  }
`;

const SchoolCard = styled(CardBox)`
  &::before {
    background-image: url(/school-bottom.svg);
    height: 105px;
    left: -57px;
    top: 315px;
  }

  &:hover {
    background-image: url(/how-it-works/Atschool.png);
  }
`;

const PlayCard = styled(CardBox)`
  &::before {
    background-image: url(/play-top.svg);
    height: 225px;
    right: -50px;
    top: 45px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    background-image: url(/how-it-works/At_Play.jpg);
  }

  @media (max-width: 767px) {
    margin-top: 30px;
  }
`;

const Heading = styled.div`
  margin: auto;
  padding-top: 65px;
`;

const StyledH2 = styled.h2`
  font-family: ProximaNova-Semibold;
  font-size: 24px;
  line-height: 29px;
  color: #282828;
`;

const CardContent = styled.div`
  min-height: 210px;
  padding: 10px 30px;

  @media (max-width: 1199px) {
    min-height: 235px;
    padding: 10px 20px;
  }
`;

const CardPara = styled.p`
  color: #282828;
  font-family: ProximaNova-Regular !important;
  font-size: 16px;
`;

const StyledLink = styled.a`
  background-color: #035ce5;
  color: #fff;
  display: block;
  margin: auto;
  text-align: center;
  width: 60%;
`;

const InspirationSection: React.FC<Props> = ({ id }) => {
  return (
    <SectionWrap
      id={id}
      className="mb-5"
      data-sal="fade"
      data-sal-delay="500"
      data-sal-duration="1000"
    >
      <Container>
        <Row>
          <Col xl={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} md={12}>
            <Row>
              <Col md={12} className="text-center mb-3">
                <StyledH3 className="mb-3">
                  Inspiring the next generation
                </StyledH3>
                <StyledPara>
                  Education plays a key role in inspiring the next generation to
                  give. 2 out of 3 Canadians who actively give to charities
                  today were exposed to conversations about charity early in
                  their lives.
                </StyledPara>
                <StyledPara>
                  Charitable Impact develops programs for schools and youth
                  sports to help children understand the act of giving, and how
                  their decisions and actions can create change.
                </StyledPara>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <SchoolCard>
                  <Heading>
                    <StyledH2>At school</StyledH2>
                  </Heading>
                  <CardContent>
                    <CardPara>
                      The Charitable Allowance Program for education invests in
                      the development and empowerment of future donors in the
                      classroom. Led by teachers or parents, this program helps
                      young people connect with their community to raise funds
                      with others to support causes of their choice.
                    </CardPara>
                  </CardContent>
                  <StyledLink
                    style={{ width: "60%" }}
                    href="https://giving.charitableimpact.com/charitable-allowance/parents"
                    className="btn btn-outline-primary btn-sm btn-learn-1"
                  >
                    Learn more
                  </StyledLink>
                </SchoolCard>
              </Col>

              <Col md={6}>
                <PlayCard>
                  <Heading>
                    <StyledH2>At play</StyledH2>
                  </Heading>
                  <CardContent>
                    <CardPara>
                      The Play Better program for sports encourages coaches and
                      parents to use charitable rewards to change the game of
                      youth sports. This program rewards players with charitable
                      dollars when they reach training goals set by themselves
                      and their coaches.
                    </CardPara>
                  </CardContent>
                  <StyledLink
                    style={{ width: "60%" }}
                    href="/atplayform"
                    className="btn btn-outline-primary btn-sm btn-learn-1"
                  >
                    Learn more
                  </StyledLink>
                </PlayCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default InspirationSection;
