import React from "react";
import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import styled from "styled-components";
import ButtonWithAnchor from "../Buttons/ButtonWithAnchor";

interface Props {
  id?: string;
}

const TabWrap = styled.section`
  background-color: #f5f8fc;

  .offset-1 {
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
`;

const HomeTab = styled.div`
  .nav-pills {
    counter-reset: number;

    @media only screen and (max-width: 767px) {
      counter-reset: list;
    }
  }
`;

const StyledNavItem = styled(Nav.Item)`
  position: relative;
  counter-increment: number;
  margin-bottom: 10px;

  &:nth-child(1) {
    .active {
      h4 {
        &::before {
          color: rgba(145, 66, 161, 0.5);
        }
      }
    }
  }

  &:nth-child(2) {
    .active {
      h4 {
        &::before {
          color: rgba(0, 158, 161, 0.5);
        }
      }
    }
  }

  &:nth-child(3) {
    .active {
      h4 {
        &::before {
          color: #fcc7ad;
        }
      }
    }
  }
`;

const StyledNavLink = styled(Nav.Link)`
  padding: 25px;
  position: relative;
  border-left: 5px solid transparent !important;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;

  &::before {
    content: "0" counter(number) " ";
    position: absolute;
    left: -80px;
    color: #c799d1;
    font-size: 50px;
    line-height: 50px;
    top: 30%;
    font-family: ProximaNova-Semibold;
    text-align: right;
    height: 61px;
    width: 60px;
    opacity: 0;

    @media only screen and (max-width: 1180px) and (min-width: 991px) {
      display: none !important;
    }

    @media only screen and (max-width: 810px) {
      display: none !important;
    }
  }

  &.active {
    background-color: #fff !important;
    -webkit-box-shadow: 0 2px 21px 0 rgb(0 0 0 / 9%);
    box-shadow: 0 2px 21px 0 rgb(0 0 0 / 9%);
    border-left: 5px solid #c799d1 !important;

    h4 {
      &::after {
        top: 3px;
        -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        transform: rotate(150deg);
        -webkit-transform: rotate(268deg);
        -ms-transform: rotate(268deg);
        transform: rotate(268deg);
      }
    }

    p {
      display: block;
    }

    &::before {
      opacity: 1;
    }

    &::after {
      content: "";
      position: absolute;
      left: -5px;
      top: 0;
      bottom: 50%;
      width: 5px;
      background-color: #9142a1;
      border-radius: 0.25rem 0 0 0;
    }

    h4 {
      color: #9142a1;
    }
  }

  &:hover {
    -webkit-box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.09);
  }
`;

const StyledNavLink2 = styled(StyledNavLink)`
  &::before {
    color: #009ea1;
  }

  &.active {
    border-color: #82e3e0 !important;

    h4 {
      color: #009ea1;
    }

    &::after {
      background-color: #009ea1;
    }
  }
`;

const StyledNavLink3 = styled(StyledNavLink)`
  &::before {
    color: #db0163;
  }

  &.active {
    border-color: #fcc7ad !important;

    h4 {
      color: #db0163;
    }

    &::after {
      background-color: #db0163;
    }
  }
`;

const BannerHeading = styled.h2`
  color: #282828;
  font-family: ProximaNova-Light;
  font-size: 30px;
  padding-bottom: 30px;
`;

const TextLeadPara = styled.p`
  margin-bottom: 0;
  font-family: ProximaNova-Regular;
  color: #5b5b5b;
  display: none;
`;

const TabHeading = styled.h4`
  font-family: ProximaNova-Semibold;
  color: #282828;
  position: relative;

  @media (max-width: 1199px) {
    font-size: 20px !important;
    padding: 0;
  }

  &::after {
    background-image: url(/icons/icon-arrow-right.png);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 16px;
    margin-left: 10px;
    position: absolute;
    top: 10px;
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    width: 16px;
  }

  &::before {
    @media only screen and (max-width: 767px) {
      counter-increment: list;
      content: "0" counter(list, number) ". ";
      color: #333;
    }
  }
`;

const StyledTabContent = styled(Tab.Content)`
  border-bottom: 1px solid #ccc;
  padding-bottom: 50px;
`;

const StyledContainer = styled(Container)`
  @media (max-width: 767px) {
    margin: auto;
    max-width: 80%;
    padding: 0;
  }
`;

const StyledRow = styled(Row)`
  padding: 80px 0;
`;

const StyledBtnWithAnchor = styled(ButtonWithAnchor)`
  font-family: ProximaNova-Regular;
  font-size: 14px;
  padding: 10px 40px;
  &::before {
    display: none;
  }
`;

const StrategiesTabSection: React.FC<Props> = ({ id }) => {
  return (
    <>
      <TabWrap
        id={id}
        className="mb-0"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-delay="500"
      >
        <StyledContainer>
          <StyledRow className="justify-content-md-center">
            <Col md={12} xl={11} lg={11} className="offset-xl-1">
              <Tab.Container id="myTabs" defaultActiveKey="giving-tab">
                <Row>
                  <Col lg={6} xl={6} md={7} sm={12}>
                    <BannerHeading className="mb-3">
                      Strategies for impactful giving
                    </BannerHeading>
                    <HomeTab className="homeTab">
                      <Nav as="ul" variant="pills" className="flex-column">
                        <StyledNavItem as="li" data-sal-delay="0">
                          <StyledNavLink eventKey="giving-tab">
                            <TabHeading>Plan your giving</TabHeading>
                            <TextLeadPara>
                              The Charitable Impact team can work with you to
                              build and manage a personalized giving plan—from
                              finding charities that match your interests to
                              giving in the most tax-effective way possible.
                            </TextLeadPara>
                          </StyledNavLink>
                        </StyledNavItem>
                        <StyledNavItem as="li" data-sal-delay="400">
                          <StyledNavLink2 eventKey="invest-tab">
                            <TabHeading>
                              Invest and grow your donations
                            </TabHeading>
                            <TextLeadPara>
                              In addition to opening an Impact Account, you can
                              also open a Charitable Investment Account for
                              longer-term investment and management of
                              charitable assets. Over time, charitable
                              investments can grow, increasing your potential
                              impact.
                            </TextLeadPara>
                          </StyledNavLink2>
                        </StyledNavItem>
                        <StyledNavItem as="li" data-sal-delay="800">
                          <StyledNavLink3 eventKey="donate-tab">
                            <TabHeading>
                              Donate cash, securities, real estate, and more
                            </TabHeading>
                            <TextLeadPara>
                              Act on your generosity in more ways than one:
                              donate publicly traded securities, real estate,
                              life insurance, cryptocurrency, and private
                              company preferred shares.
                            </TextLeadPara>
                          </StyledNavLink3>
                        </StyledNavItem>
                      </Nav>
                    </HomeTab>
                  </Col>

                  <Col lg={6} xl={6} md={5} sm={12} className="text-center">
                    <StyledTabContent>
                      <Tab.Pane eventKey="giving-tab">
                        <img
                          src="/how-it-works/plangiving@2x.png"
                          className="img-fluid"
                          alt="image"
                          loading="lazy"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="invest-tab">
                        <img
                          src="/how-it-works/investandgrow@2x.png"
                          className="img-fluid"
                          alt=""
                          loading="lazy"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="donate-tab">
                        <img
                          src="/how-it-works/donatecash.png"
                          className="img-fluid"
                          alt=""
                          loading="lazy"
                        />
                      </Tab.Pane>
                    </StyledTabContent>
                    <StyledBtnWithAnchor
                      text="Talk to one of our giving experts"
                      href="/how-it-works-form"
                      className="mt-5"
                      target="_blank"
                    />
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </StyledRow>
        </StyledContainer>
      </TabWrap>
    </>
  );
};

export default StrategiesTabSection;
