import React, { useState, useEffect } from "react";
import styled from "styled-components";

const BannerWrap = styled.section`
  min-height: 450px;
  position: relative;
  width: 100%;

  @media (max-width: 1199px) {
    min-height: 277px;
    width: 100%;
    position: relative;
  }

  @media (max-width: 767px) {
    min-height: 370px;
    overflow-x: hidden;
  }
`;

const BannerLeftWrap = styled.div`
  float: left;
  width: 50%;
`;

const BannerLeftContainer = styled.div`
  @media only screen and (max-width: 767px) {
    position: absolute;
    top: -80px;
    left: 0;
  }
  img {
    height: 384px;
    width: 500px;
    position: relative;

    @media only screen and (max-width: 1400px) {
      height: 256px;
      width: 335px;
      -o-object-fit: cover;
      object-fit: cover;
    }

    @media only screen and (max-width: 992px) {
      width: 250px;
      height: 247px;
    }
  }
`;

const BannerContent = styled.div`
  position: absolute;
  max-height: 100%;
  max-width: 450px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`;

const BannerHeading = styled.h1`
  font-family: ProximaNova-Light;
  font-size: 40px;
  color: #424242;

  @media only screen and (max-width: 992px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 85px;
  }
`;

const BannerRightWrap = styled.div`
  width: 50%;
  float: right;
`;

const BannerRightContainer1 = styled.div`
  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 380px;
    height: 340px;
    float: right;
    -o-object-fit: cover;
    object-fit: cover;

    @media only screen and (max-width: 1400px) {
      width: 295px;
      height: 280px;
    }

    @media only screen and (max-width: 992px) {
      width: 252px;
      height: 260px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`;

const BannerRightContainer2 = styled.div`
  position: absolute;
  width: 153px;
  float: right;
  right: 17%;
  top: 44%;

  @media only screen and (max-width: 1199px) {
    right: 18%;
    top: 55%;
  }

  @media only screen and (max-width: 767px) {
    right: -130px;
    top: 60%;
  }

  @media only screen and (max-width: 480px) {
    width: 105px;
    right: -15px;
    top: 75%;
  }
`;

const BannerSection = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <BannerWrap>
      <BannerLeftWrap style={{ transform: `translateY(-${offsetY * 2}px)` }}>
        <BannerLeftContainer className="animate__animated animate__fadeInUp animate__slow">
          <img src="/how-it-left-banner-1.png" alt="image" loading="lazy" />
        </BannerLeftContainer>
      </BannerLeftWrap>

      <BannerContent
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="100"
      >
        <BannerHeading>How it works</BannerHeading>
      </BannerContent>

      <BannerRightWrap>
        <BannerRightContainer1 className="animate__animated animate__fadeInUp animate__slow">
          <img src="/right-banner-1.png" alt="" loading="lazy" />
        </BannerRightContainer1>
        <BannerRightContainer2 className="animate__animated animate__fadeInUp animate__slow">
          <img src="/right-shap-2.png" alt="" loading="lazy" />
        </BannerRightContainer2>
      </BannerRightWrap>
    </BannerWrap>
  );
};

export default BannerSection;
