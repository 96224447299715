import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  padding: 70px 0;
`;

const StyledH3 = styled.h3`
  color: #282828;
  font-family: ProximaNova-Light;
  font-size: 30px;
  font-weight: 300;
  line-height: 37px;
  margin-bottom: 26px;
  margin-top: 28px;
  text-align: center;
`;

const StyledH5 = styled.h5`
  color: #282828;
  font-family: ProximaNova-Regular;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 30px;
  padding-top: 20px;
`;

const StyledPara = styled.p`
  color: #7f7f7f;
  line-height: 28px;
  text-align: center;
  font-family: ProximaNova-Regular;
  font-size: 18px;
`;

const StyledImage = styled.img`
  height: 100px;
  margin-bottom: 10px;
`;

const SafeSecureSection = () => {
  return (
    <SectionWrap
      className="mt-5"
      data-sal="fade"
      data-sal-duration="1000"
      data-sal-delay="500"
    >
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <StyledH3>Safe and secure</StyledH3>
          </Col>

          <Col md={4} className="mt-5 text-center">
            <StyledImage src="/how-it-works/lock@2x.png" />
            <StyledH5>Strong security</StyledH5>
            <StyledPara>
              We use the same security and encryption as online banks to keep
              your information safe and protect your privacy.
            </StyledPara>
          </Col>

          <Col md={4} className="mt-5 text-center">
            <StyledImage src="/how-it-works/accountableimpact@2x.png" />
            <StyledH5>Accountable impact</StyledH5>
            <StyledPara>
              Our financial records are audited every year by MNP, a leading
              accounting firm.
            </StyledPara>
          </Col>

          <Col md={4} className="mt-5 text-center">
            <StyledImage src="/how-it-works/experience-team@2x.png" />
            <StyledH5>Experienced team</StyledH5>
            <StyledPara>
              Our staff, board members, and advisors are experts in charitable
              giving, charity law, and technology.
            </StyledPara>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default SafeSecureSection;
